define("ember-component-css/initializers/component-styles", ["exports", "ember", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/application", "ember-component-css/pod-names"], function (_exports, _ember, _component, _object, _computed, _application, _podNames) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  var ComponentLookup = _ember.default.ComponentLookup;
  ComponentLookup.reopen({
    componentFor: function componentFor(name, owner) {
      owner = owner.hasRegistration ? owner : (0, _application.getOwner)(this);
      if (_podNames.default[name] && !owner.hasRegistration("component:".concat(name))) {
        owner.register("component:".concat(name), _component.default);
      }
      return this._super.apply(this, arguments);
    }
  });
  _component.default.reopen({
    _componentIdentifier: (0, _object.computed)({
      get: function get() {
        return (this._debugContainerKey || '').replace('component:', '');
      }
    }),
    _shouldAddNamespacedClassName: (0, _object.computed)({
      get: function get() {
        return this.get('tagName') !== '' && this.get('styleNamespace');
      }
    }),
    styleNamespace: (0, _object.computed)({
      get: function get() {
        return _podNames.default[this.get('_componentIdentifier')] || '';
      }
    }),
    // componentCssClassName: deprecatingAlias('styleNamespace', {
    //   id: 'ember-component-css.deprecate-componentCssClassName',
    //   until: '0.7.0',
    // }),

    componentCssClassName: (0, _computed.alias)('styleNamespace'),
    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('_shouldAddNamespacedClassName')) {
        this.classNames = this.classNames.concat(this.get('styleNamespace'));
      }
    }
  });
  function initialize() {}
  var _default = {
    initialize: initialize
  };
  _exports.default = _default;
});