define("ember-intl/helpers/-format-base", ["exports", "@ember/component/helper", "@ember/application", "@ember/utils"], function (_exports, _helper, _application, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0); } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  /**
   * @private
   * @hide
   */
  var AbstractHelper = _helper.default.extend({
    intl: null,
    init: function init() {
      if (this.constructor === AbstractHelper) {
        throw new Error('FormatHelper is an abstract class, can not be instantiated directly.');
      }
      this._super();
      this.intl = (0, _application.getOwner)(this).lookup('service:intl');
      this.intl.on('localeChanged', this, 'recompute');
    },
    format: function format() {
      throw new Error('not implemented');
    },
    compute: function compute(_ref, options) {
      var _ref2 = _slicedToArray(_ref, 1),
        value = _ref2[0];
      if ((0, _utils.isEmpty)(value)) {
        var _options$allowEmpty;
        if ((_options$allowEmpty = options.allowEmpty) !== null && _options$allowEmpty !== void 0 ? _options$allowEmpty : this.allowEmpty) {
          return;
        }
        if (typeof value === 'undefined') {
          throw new Error("".concat(this, " helper requires value attribute."));
        }
      }
      return this.format(value, options);
    },
    willDestroy: function willDestroy() {
      this._super();
      this.intl.off('localeChanged', this, 'recompute');
    }
  });
  var _default = AbstractHelper;
  _exports.default = _default;
});