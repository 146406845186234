define("ember-concurrency/-private/async-arrow-runtime", ["exports", "ember-concurrency/-private/task-factory"], function (_exports, _taskFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildTask = buildTask;
  /**
   * This builder function is called by the transpiled code from
   * `task(async () => {})`. See lib/babel-plugin-transform-ember-concurrency-async-tasks.js
   *
   * @private
   */
  function buildTask(contextFn, options, taskName, bufferPolicyName) {
    var optionsWithBufferPolicy = options;
    if (bufferPolicyName) {
      optionsWithBufferPolicy = Object.assign({}, optionsWithBufferPolicy);
      optionsWithBufferPolicy[bufferPolicyName] = true;
    }
    var result = contextFn();
    var taskFactory = new _taskFactory.TaskFactory(taskName || '<unknown>', result.generator, optionsWithBufferPolicy);
    return taskFactory.createTask(result.context);
  }
});