define("ember-math-helpers/helpers/mult", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.mult = mult;
  /**
   * Multiplies two or more numbers
   *
   * ```hbs
   * {{mult a b}}
   * ```
   *
   * @param {number[]} numbers A list of numbers to multiply
   * @return {number} The product of all the passed numbers
   */
  function mult(numbers) {
    return numbers.reduce(function (a, b) {
      return Number(a) * Number(b);
    });
  }
  var _default = (0, _helper.helper)(mult);
  _exports.default = _default;
});