define("ember-composable-helpers/-private/get-value-array-and-use-deep-equal-from-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getValueArrayAndUseDeepEqualFromParams;
  function getValueArrayAndUseDeepEqualFromParams(params) {
    var currentValue = params[0];
    var array;
    var useDeepEqual = false;
    if (params.length === 2) {
      array = params[1];
    } else {
      useDeepEqual = params[1];
      array = params[2];
    }
    return {
      currentValue: currentValue,
      array: array,
      useDeepEqual: useDeepEqual
    };
  }
});