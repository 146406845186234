define("ember-changeset/utils/wrap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = wrapInArray;
  /**
   * Wraps a value in an Ember.Array.
   *
   * @public
   * @param  {Any} value
   * @return {Ember.Array}
   */
  function wrapInArray(value) {
    if (Array.isArray(value)) {
      return value;
    }
    return [value];
  }
});