define("ember-composable-helpers/helpers/toggle-action", ["exports", "@ember/component/helper", "ember-composable-helpers/helpers/toggle", "ember-composable-helpers/-private/closure-action"], function (_exports, _helper, _toggle, _closureAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var closureToggle = _toggle.toggle;
  if (_closureAction.default) {
    closureToggle[_closureAction.default] = true;
  }
  var _default = (0, _helper.helper)(closureToggle);
  _exports.default = _default;
});